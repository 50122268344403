import { isPlatformBrowser } from '@angular/common';
import { Component, effect, HostListener, inject, PLATFORM_ID, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ArticlesService } from 'shared-ui/domain/articles/articles.service';
import { rxSignal } from 'shared-ui/utils';

@Component({
  selector: 'retail-app-root',
  templateUrl: './retail-app.component.html',
  styleUrls: ['./retail-app.component.scss'],
})
export class RetailAppComponent {
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private articlesService = inject(ArticlesService);

  hideFloatTop = signal(true);
  contactEmail = signal('obchod.berop@seznam.cz');
  categories = rxSignal(this.getCategories(), []);
  routerEvent = toSignal(this.router.events);

  constructor() {
    effect(() => {
      if (this.routerEvent() instanceof NavigationEnd) this.goTop();
    });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const verticalOffset = window.scrollY ?? 0;
      this.hideFloatTop.set(verticalOffset < 700);
    }
  }

  goTop() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => window.scroll(0, 0));
    }
  }

  private getCategories() {
    return this.articlesService.load().pipe(map(value => this.articlesService.getArticlesCategories(value)));
  }
}
