import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from 'retail/app/core/components/homepage/homepage.component';
import { NotFoundComponent } from 'shared-ui/components/not-found/not-found.component';

const routes: Routes = [
  { path: 'products/:name/:id', pathMatch: 'full', redirectTo: '/p/products/:name/:id' },
  { path: 'product/:name/:id', pathMatch: 'full', redirectTo: '/p/product/:name/:id' },
  { path: 'obsah/:category/:article', pathMatch: 'full', redirectTo: '/i/obsah/:category/:article' },
  { path: 'obsah/:category', pathMatch: 'full', redirectTo: '/i/obsah/:category' },
  { path: 'revize', pathMatch: 'full', redirectTo: '/tickets' },
  { path: 'kontakty', pathMatch: 'full', redirectTo: '/i/kontakty' },
  {
    path: 'p',
    loadChildren: () => import('./products/retail-products.module').then(m => m.RetailProductsModule),
  },
  {
    path: 'o',
    loadChildren: () => import('./orders/retail-orders.module').then(m => m.RetailOrdersModule),
  },
  {
    path: 'tickets',
    loadChildren: () => import('./inspections/retail-inspections.module').then(m => m.RetailInspectionsModule),
  },
  {
    path: 'i',
    loadChildren: () => import('./info/retail-info.module').then(m => m.RetailInfoModule),
  },
  { path: '', component: HomepageComponent, pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RetailAppRoutingModule {}
