import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { plainToInstance } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface Brand {
  imageUrl: string;
  label: string;
}

export interface Widget {
  url: string;
  image?: string;
  logo?: string;
  title?: string;
  list?: string[];
  description?: string;
}

@Injectable()
export class HomepageService {
  constructor(private http: HttpClient) {}

  getLastProducts(): Observable<ProductEntity[]> {
    return this.http.get<ProductEntity[]>(`products/last?limit=8`).pipe(
      map(res => plainToInstance(ProductEntity, res)),
      catchError(this.handleError([]))
    );
  }

  getRecProducts(): Observable<ProductEntity[]> {
    const params = new HttpParams({
      fromObject: {
        categoryId: '5d41af21c01ad0e56cb75a92',
      },
    });
    return this.http.get<{ items: ProductEntity[] }>(`products/grid?${params}`).pipe(
      map(res => plainToInstance(ProductEntity, res.items)),
      map(products => this.randomProducts(products)),
      catchError(this.handleError([]))
    );
  }

  getWidgets(): Observable<Widget[]> {
    return of([
      {
        url: '/products/kotle/5d41af21c01ad0e56cb75a92?b=61446d2303d9d6000db816da',
        logo: '/assets/brands/viaflames.png',
        title: 'Všechny kotle VIAFLAMES máme skladem!!',
        list: [
          'Splňuje emisní třídu 5 dle ČSN EN',
          'Vysoká účinnost až 90,9%',
          'Nízké náklady na vytápění',
          '7 letá záruka na kotlové těleso',
        ],
      },
      {
        url: '/kotlikove-dotace',
        image: '/assets/dotace-na-kotle.jpg',
      },
      {
        url: '/obsah/vse-o-nakupu/doprava-a-platba',
        image: '/assets/zasilkovna-banner.png',
      },
      {
        url: '/kontakty',
        title: 'Aktuální nabídka zboží',
      },
    ]);
  }

  getBrands(): Observable<Brand[]> {
    return of([
      {
        label: 'Viaflames',
        imageUrl: '/assets/brands/viaflames.webp',
      },
      {
        label: 'Brotje',
        imageUrl: '/assets/brands/brotje.webp',
      },
      {
        label: 'OPOP',
        imageUrl: '/assets/brands/opop.webp',
      },
      {
        label: 'De Dietrich',
        imageUrl: '/assets/brands/dedietrich.webp',
      },
      {
        label: 'Regulus',
        imageUrl: '/assets/brands/regulus.webp',
      },
      {
        label: 'Nibe',
        imageUrl: '/assets/brands/nibe.webp',
      },
      {
        label: 'Grundfos',
        imageUrl: '/assets/brands/grundfos.webp',
      },
      {
        label: 'Danfoss',
        imageUrl: '/assets/brands/danfoss.webp',
      },
      {
        label: 'Marolex',
        imageUrl: '/assets/brands/marolex.webp',
      },
      {
        label: 'Toro',
        imageUrl: '/assets/brands/toro.webp',
      },
    ]);
  }

  private randomProducts(products: ProductEntity[]) {
    const recProducts = products.filter(item => item.price > 25000);
    const max = recProducts.length - 1;
    const result: ProductEntity[] = [];
    for (let i = 1; i <= 8; i++) {
      let random = i * 3 * new Date().getDate();
      while (random > max) random -= max;
      const product = recProducts[random];
      if (product) result.push(product);
    }
    return result;
  }

  protected handleError<T>(result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      this.logError(error);
      return of(result) as Observable<T>;
    };
  }

  protected logError(error: Error): void {
    console.error(error);
  }
}
