import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CategoriesStoreService } from 'shared-ui/domain/products/categories-store.service';
import { ProgressService } from 'shared-ui/providers/progress.service';
import { rxSignal } from 'shared-ui/utils';

@Component({
  selector: 'app-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  private dataService = inject(CategoriesStoreService);
  private progressService = inject(ProgressService);

  categories = rxSignal(this.dataService.storeGetTree(), []);
  menuOpened = rxSignal(this.progressService.menuToggle, false);

  closeMenu() {
    this.progressService.menuToggle.next(false);
  }
}
