import { Injectable } from '@angular/core';
import { logEvent, Analytics } from '@angular/fire/analytics';
import { trace, PerformanceTrace, Performance } from '@angular/fire/performance';
import { Dictionary } from '@shared/types';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private traces = new Map<string, PerformanceTrace>();

  constructor(private analyticsService: Analytics, private performanceService: Performance) {}

  logEvent(name: string, params?: Dictionary) {
    try {
      logEvent(this.analyticsService, name, params);
    } catch (error) {
      console.warn(error);
    }
  }

  createTrace(name: string) {
    return trace(this.performanceService, name);
  }

  traceStart(name: string) {
    const trace = this.createTrace(name);
    this.traces.set(name, trace);
    trace.start();
    return trace;
  }

  traceStop(name: string) {
    const trace = this.traces.get(name);
    try {
      trace?.stop();
    } catch (error) {
      console.warn(error);
    }
    this.traces.delete(name);
    return trace;
  }
}
