import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { environment } from 'retail/environments/environment';
import { Observable } from 'rxjs';
import { getFromGlobal } from 'shared-ui/di/get-from-global';
import { Packeta, Zasilkovna } from 'shared-ui/providers/zasilkovna';

@Injectable({ providedIn: 'root' })
export class ZasilkovnaPickup {
  private readonly client = getFromGlobal<Packeta>('Packeta');
  private readonly clientApiKey = environment.zasilkovna.apiKey;
  private readonly defualtOptions: Zasilkovna.PickOptions = {
    country: environment.zasilkovna.country,
    language: environment.zasilkovna.language,
  };

  pick(options: Zasilkovna.PickOptions = {}): Observable<null | Zasilkovna.ExtendedPoint> {
    const pickOptions = merge({}, this.defualtOptions, options);
    return new Observable(subscriber => {
      this.client.Widget.pick(
        this.clientApiKey,
        point => {
          subscriber.next(point);
          subscriber.complete();
        },
        pickOptions
      );
    });
  }
}
