import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CartService } from 'retail/app/common/providers/cart.service';
import { CreateOrderModel } from 'retail/app/orders/models/create-order.model';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { StorageService } from 'shared-ui/providers/storage-service';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  static readonly STORAGE_KEY: string = 'ORDER';

  private orderCleared$ = new EventEmitter();
  private orderUpdate$ = new EventEmitter<Partial<CreateOrderModel>>();

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  static getTotal(order: Partial<CreateOrderModel>): number {
    const deliveryCost = order.delivery?.price ?? 0;
    const paymentCost = order.payment?.price ?? 0;
    const itemsCost = CartService.getItemsPrice(order.cart ?? []);
    return deliveryCost + paymentCost + itemsCost;
  }

  get orderCleared() {
    return this.orderCleared$;
  }

  get orderUpdate() {
    return this.orderUpdate$;
  }

  sendOrder(order: CreateOrderModel): Observable<unknown> {
    return this.http.post('public/orders', order).pipe(delay(1000));
  }

  clearOrder() {
    this.storageService.removeItem(OrdersService.STORAGE_KEY);
    this.orderCleared$.emit();
  }

  loadOrder(): Partial<CreateOrderModel> {
    const order = this.storageService.getItem(OrdersService.STORAGE_KEY);
    return JSON.parse(order ?? '{}');
  }

  loadFullOrder(): CreateOrderModel | undefined {
    const order = this.loadOrder();
    if (this.validateOrder()) {
      return order as CreateOrderModel;
    }
    return;
  }

  saveOrder(order: Partial<CreateOrderModel>) {
    this.storageService.setItem(OrdersService.STORAGE_KEY, JSON.stringify(order));
    this.orderUpdate$.emit(order);
  }

  patchOrder<K extends keyof CreateOrderModel>(key: K, value: CreateOrderModel[K]) {
    const order = this.loadOrder();
    order[key] = value;
    order.total = OrdersService.getTotal(order);
    this.saveOrder(order);
    return order;
  }

  clearPayment() {
    const order = this.loadOrder();
    order.payment = undefined;
    order.total = OrdersService.getTotal(order);
    this.saveOrder(order);
  }

  validateOrder() {
    const order = this.loadOrder();
    const infoValid = !!order.info;
    return this.validateOrderDelivery() && infoValid;
  }

  validateOrderDelivery() {
    const order = this.loadOrder();
    const deliveryValid = !!order.delivery;
    const paymentValid = !!order.payment;
    return this.validateOrderCart() && deliveryValid && paymentValid;
  }

  validateOrderCart() {
    const order = this.loadOrder();
    const cartValid = (order.cart ?? []).length > 0;
    const totalValid = (order.total ?? 0) > 0;
    return cartValid && totalValid;
  }
}
