import { Injectable } from '@angular/core';
import { EventNameString } from '@angular/fire/analytics';
import { ProductGridEntity } from '@shared/domain/products/product-grid.entity';
import { Dictionary } from '@shared/types';
import { CartItem } from 'retail/app/common/models/cart-item';
import { CartService } from 'retail/app/common/providers/cart.service';
import { CreateOrderModel } from 'retail/app/orders/models/create-order.model';
import { VAT } from 'shared-ui/models/vat';
import { AnalyticsService } from 'shared-ui/providers/analytics.service';

@Injectable({ providedIn: 'root' })
export class RetailAnalyticsService {
  readonly currency = 'CZK';

  constructor(private service: AnalyticsService) {}

  logViewItem(product: ProductGridEntity) {
    this.logEvent('view_item', {
      currency: this.currency,
      value: product.price * VAT,
      items: [this.getProductLogItem(product)],
    });
  }

  logAddToCart(product: ProductGridEntity, quantity: number) {
    this.logEvent('add_to_cart', {
      currency: this.currency,
      value: product.price * VAT * quantity,
      items: [{ ...this.getProductLogItem(product), quantity }],
    });
  }

  logViewCart(items: CartItem[]) {
    this.logEvent('view_cart', {
      currency: this.currency,
      value: CartService.getItemsPrice(items),
      items: this.getCartLogItems(items),
    });
  }

  logRemoveFromCart(item: CartItem) {
    this.logEvent('remove_from_cart', {
      currency: this.currency,
      value: item.price * VAT,
      items: this.getCartLogItems([item]),
    });
  }

  logCheckoutBegin(order: Partial<CreateOrderModel>) {
    this.logEvent('begin_checkout', {
      currency: this.currency,
      value: order.total ?? 0,
      items: this.getCartLogItems(order.cart ?? []),
    });
  }

  logCheckoutShipping(order: Partial<CreateOrderModel>) {
    this.logEvent('add_shipping_info', {
      currency: this.currency,
      value: order.total ?? 0,
      items: this.getCartLogItems(order.cart ?? []),
    });
  }

  logCheckoutPayment(order: Partial<CreateOrderModel>) {
    this.logEvent('add_payment_info', {
      currency: this.currency,
      value: order.total ?? 0,
      items: this.getCartLogItems(order.cart ?? []),
    });
  }

  logPurchase(order: CreateOrderModel) {
    this.logEvent('purchase', {
      currency: this.currency,
      value: order.total,
      tax: order.total - order.total / VAT,
      shipping: order.delivery.price,
      items: this.getCartLogItems(order.cart),
    });
  }

  private logEvent(name: EventNameString, params?: Dictionary) {
    this.service.logEvent(name, params);
  }

  private getProductLogItem(product: ProductGridEntity) {
    return {
      item_id: product._id,
      item_name: product.name,
      item_category: product.categoryName,
      item_brand: product.manufacturerName,
      price: product.price * VAT,
    };
  }

  private getCartLogItems(items: CartItem[]) {
    return items.map(item => {
      return {
        item_id: item.id,
        item_name: item.name,
        price: item.price * VAT,
      };
    });
  }
}
