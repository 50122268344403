import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ProductGridEntity } from '@shared/domain/products/product-grid.entity';
import { isNumber } from 'lodash';
import { ENVIRONMENT, SharedEnv } from 'shared-ui/di/environment';

@Pipe({
  name: 'productImageUrl',
})
export class ProductImageUrlPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private env: SharedEnv) {}

  transform(product: ProductGridEntity, index?: number): string {
    const image = isNumber(index) ? product.images[index] : product.defaultImage;
    return `${this.env.imagesUrl}/products/${product._id}/${image}`;
  }
}
