import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HeaderComponent } from 'retail/app/core/components/header/header.component';
import { HomepageComponent } from 'retail/app/core/components/homepage/homepage.component';
import { MenuComponent } from 'retail/app/core/components/menu/menu.component';
import { SearchComponent } from 'retail/app/core/components/search/search.component';
import { AuthGuard } from 'retail/app/core/providers/auth.guard';
import { AuthService } from 'retail/app/core/providers/auth.service';
import { HomepageService } from 'retail/app/core/providers/homepage.service';
import { SharedUiModule } from 'shared-ui/shared-ui.module';

@NgModule({
  declarations: [HeaderComponent, HomepageComponent, MenuComponent, SearchComponent],
  providers: [AuthGuard, AuthService, HomepageService],
  imports: [
    CommonModule,
    HttpClientModule,
    LazyLoadImageModule,
    ReactiveFormsModule,
    RouterModule,
    SharedUiModule,
    NgOptimizedImage,
  ],
  exports: [HeaderComponent, HomepageComponent, MenuComponent],
})
export class RetailCoreModule {}
