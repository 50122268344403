import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { CartService } from 'retail/app/common/providers/cart.service';
import { ProgressService } from 'shared-ui/providers/progress.service';
import { rxSignal } from 'shared-ui/utils';

@Component({
  selector: 'app-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private cartService = inject(CartService);
  private progressService = inject(ProgressService);

  items = rxSignal(this.cartService.cartUpdate, this.cartService.cartItems);
  itemsPrice = computed(() => {
    return this.items() && this.cartService.getItemsPrice();
  });

  toggleMenu() {
    const menuOpened = this.progressService.menuToggle.getValue();
    this.progressService.menuToggle.next(!menuOpened);
  }
}
