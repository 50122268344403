import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { CategoryEntity } from '@shared/domain/products/category.entity';
import { ProductGridEntity } from '@shared/domain/products/product-grid.entity';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { Dictionary } from '@shared/types';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { CategoriesStoreService } from 'shared-ui/domain/products/categories-store.service';
import { Find } from 'shared-ui/models/find';
import { CrudHttpService } from 'shared-ui/providers/crud-http-service';
import { FlashMessages } from 'shared-ui/providers/flash-messages';
import { Translator } from 'shared-ui/providers/translator';

@Injectable({ providedIn: 'root' })
export class RetailProductsService extends CrudHttpService<ProductEntity> {
  protected baseUrl = 'products';
  protected entityClass = ProductEntity;

  constructor(
    protected errorHandler: ErrorHandler,
    protected flash: FlashMessages,
    protected http: HttpClient,
    protected translator: Translator,
    protected categoriesService: CategoriesStoreService
  ) {
    super(errorHandler, flash, http, translator);
  }

  getCategory(id: string): Observable<CategoryEntity | undefined> {
    return this.categoriesService.storeGet(id);
  }

  getProductAndCategory(productId: string): Observable<[ProductEntity | undefined, CategoryEntity | undefined]> {
    return this.get(productId).pipe(
      switchMap(product => {
        return forkJoin([of(product), this.getCategory(product?.categoryId ?? '')]);
      })
    );
  }

  getProducts(categoryId: string): Observable<{ items: ProductGridEntity[]; total: number }> {
    const query = `products/grid?categoryId=${categoryId}&$sort=price:asc`;
    return this.http.get<{ items: ProductGridEntity[]; total: number }>(query).pipe(
      delay(this.DELAY),
      catchError(error => this.handleQueryError(error, { total: 0, items: [] }))
    );
  }

  getProductAccessories(product: ProductEntity): Observable<ProductEntity[]> {
    if (product.accessories.length === 0) return of([]);
    const query: Find = {
      filter: { _id: product.accessories },
    };
    return this.find(query).pipe(map(value => value.items));
  }

  searchProducts(search: string, limit?: number): Observable<ProductEntity[]> {
    const filter: Dictionary<any> = { s: search, limit };
    const query = new HttpParams({ fromObject: filter });
    return this.http.get<ProductEntity[]>(`products/search?${query}`).pipe(
      delay(this.DELAY),
      catchError(error => this.handleQueryError(error, []))
    );
  }
}
