import { HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { disableAnimations } from 'retail/app/disable-animations';
import { RetailAnalyticsService } from 'retail/app/retail-analytics.service';
import { environment } from 'retail/environments/environment';
import { RetailCommonModule } from 'retail/app/common/retail-common.module';
import { RetailCoreModule } from 'retail/app/core/retail-core.module';
import { RetailAppRoutingModule } from 'retail/app/retail-app-routing.module';
import { RetailAppComponent } from 'retail/app/retail-app.component';
import { createEnvProvider } from 'shared-ui/di/environment';
import { AnalyticsService } from 'shared-ui/providers/analytics.service';
import { AppErrorHandler } from 'shared-ui/providers/app-error-handler';
import { AppLogger } from 'shared-ui/providers/app-logger';
import { BaseInterceptor } from 'shared-ui/providers/base-interceptor';
import { getLanguage, getTranslations, TRANSLATIONS } from 'shared-ui/providers/locales';
import { ZasilkovnaPickup } from 'shared-ui/providers/zasilkovna-pickup';
import { SharedUiModule } from 'shared-ui/shared-ui.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { providePerformance, getPerformance } from '@angular/fire/performance';

@NgModule({
  bootstrap: [RetailAppComponent],
  declarations: [RetailAppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations: disableAnimations() }),
    RetailCommonModule,
    RetailCoreModule,
    SharedUiModule,
    RetailAppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideFirestore(() => getFirestore()),
    providePerformance(() => getPerformance()),
  ],
  providers: [
    createEnvProvider(environment),
    provideHttpClient(withFetch()),
    provideClientHydration(),
    AppLogger,
    ZasilkovnaPickup,
    ScreenTrackingService,
    AnalyticsService,
    RetailAnalyticsService,
    { provide: LOCALE_ID, useFactory: getLanguage },
    { provide: TRANSLATIONS, useFactory: getTranslations },
    { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
  ],
})
export class RetailAppModule {}
