import { Injectable } from '@angular/core';
import { CategoryEntity } from '@shared/domain/products/category.entity';
import { listToTree } from '@shared/model/trees';
import { Dictionary } from '@shared/types';
import { keyBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';

@Injectable()
export class CategoriesStoreService extends CategoriesService {
  protected store?: Dictionary<CategoryEntity>;
  protected $store?: Observable<Dictionary<CategoryEntity>>;

  storeGet(id: string): Observable<CategoryEntity | undefined> {
    return this.getStore().pipe(map(value => value[id]));
  }

  storeGetTree(): Observable<CategoryEntity[]> {
    return this.getStore().pipe(map(value => listToTree(Object.values(value))));
  }

  storeGetAll(): Observable<Dictionary<CategoryEntity>> {
    return this.getStore();
  }

  protected getStore() {
    if (this.store) return of(this.store);
    if (!this.$store) this.$store = this.createStore();
    return this.$store;
  }

  protected createStore() {
    return this.find().pipe(
      shareReplay(1),
      map(result => (this.store = keyBy(result.items, '_id')))
    );
  }
}
