<div class="mobile-row">
  <div class="container">
    <div class="row">
      <div class="col-auto">
        <button mat-button (click)="toggleMenu()" color="primary">
          <icon icon="menu" />
        </button>
      </div>
      <div class="col-auto">
        <a href="/" class="b-link">BEROP.CZ</a>
      </div>
      <div class="col col-buttons">
        <a routerLink="/o/kosik" mat-raised-button color="primary"><icon icon="shopping_cart"></icon></a>
      </div>
    </div>
  </div>
  <app-header-search class="p-1" />
</div>

<div class="top-row">
  <div class="container">
    <div class="row">
      <div class="col">
        <a routerLink="/i/obsah/vse-o-nakupu">Vše o nákupu</a>
        <a routerLink="/i/obsah/sluzby">Služby</a>
        <a routerLink="/i/obsah/o-spolecnosti">O společnosti</a>
        <a routerLink="/i/kotlikove-dotace"><span>Kotlíkové dotace</span></a>
        <a routerLink="/tickets">Revize</a>
        <a routerLink="/i/kontakty">Kontakty</a>
      </div>
      <div class="col">
        <!--                <a routerLink="user/sign-up">Registrace</a>-->
        <!--                <a routerLink="user/sign-in">Přihlásit se</a>-->
      </div>
    </div>
  </div>
</div>

<div class="middle-row">
  <div class="container">
    <div class="row">
      <div class="col logo">
        <a href="/">BEROP.CZ</a>
      </div>
      <div class="col search">
        <app-header-search />
      </div>
      <div class="col cart">
        <button
          routerLink="/o/kosik"
          class="cart-button"
          mat-stroked-button
          color="primary"
          [matBadge]="items().length"
          [matBadgeHidden]="!items().length"
          matBadgeColor="warn">
          <icon icon="shopping_cart" />
          @if (itemsPrice() > 0) {
            {{ itemsPrice() | localeCurrency }}
          } @else {
            {{ 'Empty Cart' | t }}
          }
        </button>
      </div>
    </div>
  </div>
</div>
