import { Pipe, PipeTransform } from '@angular/core';
import { deburr, kebabCase } from 'lodash';

interface UrlModel {
  name: string;
  url?: string;
}

@Pipe({
  name: 'urlLink',
  pure: true,
})
export class UrlLinkPipe implements PipeTransform {
  transform(value: UrlModel): string {
    return UrlLinkPipe.getUrl(value);
  }

  static getUrl(value: UrlModel): string {
    return value.url ? value.url : kebabCase(deburr(value.name));
  }
}
