<nav class="container">
  @for (root of categories(); track root._id) {
    <div class="item">
      <a [routerLink]="['/p/products', root | urlLink, root._id]" class="link">{{ root.name }}</a>
      <div class="sublist">
        <div class="row">
          @for (child of root.children; track child._id) {
            <div class="col-3">
              <a [routerLink]="['/p/products', child | urlLink, child._id]">
                <b>{{ child.name }}</b>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  }
</nav>

<nav class="mobile-menu {{ menuOpened() ? 'mobile-menu__open' : '' }}">
  <h3>Kategorie</h3>
  <div>
    @for (root of categories(); track root._id) {
      <a [routerLink]="['/p/products', root | urlLink, root._id]" (click)="closeMenu()" mat-button color="primary">
        {{ root.name }}
      </a>
    }
  </div>

  <h3>Obchod</h3>
  <div>
    <a routerLink="/i/obsah/vse-o-nakupu" (click)="closeMenu()" mat-button color="primary">Vše o nákupu</a>
    <a routerLink="/i/obsah/sluzby" (click)="closeMenu()" mat-button color="primary">Služby</a>
    <a routerLink="/i/obsah/o-spolecnosti" (click)="closeMenu()" mat-button color="primary">O společnosti</a>
    <a routerLink="/tickets" (click)="closeMenu()" mat-button color="primary">Revize</a>
    <a routerLink="/i/kontakty" (click)="closeMenu()" mat-button color="primary">Kontakty</a>
  </div>
</nav>
