import { NgModule } from '@angular/core';
import { CartService } from 'retail/app/common/providers/cart.service';
import { RetailProductsService } from 'retail/app/common/providers/retail-products.service';
import { CategoriesStoreService } from 'shared-ui/domain/products/categories-store.service';
import { CategoriesService } from 'shared-ui/domain/products/categories.service';

@NgModule({
  providers: [CartService, CategoriesService, CategoriesStoreService, RetailProductsService],
})
export class RetailCommonModule {}
