import { Pipe, PipeTransform } from '@angular/core';
import { VAT } from 'shared-ui/models/vat';

@Pipe({
  name: 'customerPrice',
})
export class CustomerPricePipe implements PipeTransform {
  transform(value: number): number {
    return value * VAT;
  }
}
