import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HomepageService } from 'retail/app/core/providers/homepage.service';
import { of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { rxSignal } from 'shared-ui/utils';

@Component({
  selector: 'app-homepage',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent {
  private platformId = inject(PLATFORM_ID);
  private meta = inject(Meta);
  private title = inject(Title);
  private homepageService = inject(HomepageService);

  readonly defaultImage = '/assets/placeholder.jpg';
  brands = rxSignal(this.homepageService.getBrands(), []);
  showedBrands = rxSignal(this.getBrands(), []);
  lastProducts = rxSignal(this.homepageService.getLastProducts(), []);
  recProducts = rxSignal(this.homepageService.getRecProducts(), []);

  constructor() {
    this.title.setTitle('BEROP.cz');
    this.meta.updateTag({
      name: 'keywords',
      content:
        'kotle, tepelné čerpadla, dům a zahrada, sekačky, náhradní díly, montáže, servis, opava, ostrava, viaflames, opop, regulus',
    });
    this.meta.updateTag({
      name: 'description',
      content:
        'Obchod s kotli, tepelnými čerpadly, jejich montáž a servis, a také prodej sortimentu pro Vaši zahradu a náhradních dílů.',
    });
  }

  private getBrands() {
    const interval = isPlatformBrowser(this.platformId) ? timer(0, 5_000) : of(0);
    return interval.pipe(
      map(seq => {
        const brands = this.brands();
        const isEven = seq % 2 === 0;
        return isEven ? brands.slice(0, 5) : brands.slice(5);
      })
    );
  }
}
