export const environment = {
  production: true,
  apiUrl: 'https://berop-api-prod-kt6tyifvma-ey.a.run.app',
  filesUrl: 'https://storage.googleapis.com/berop-shop-products-prod',
  imagesUrl: 'https://storage.googleapis.com/berop-shop-products-prod',
  sentry: {
    enabled: true,
    dsn: 'https://5d1a63e97ca047d29a115472c75b62bc@o290297.ingest.sentry.io/1529494',
  },
  zasilkovna: {
    apiKey: '5ba8664fd03968de',
    country: 'cz',
    language: ['cz', 'sk'],
  },
  firebase: {
    projectId: 'berop-ce6f7',
    appId: '1:362585336247:web:d42ec42e97caeeaee92032',
    storageBucket: 'berop-ce6f7.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAy78OUK17MXEPWqP1NuTSMt4eK452m2Tw',
    authDomain: 'berop-ce6f7.firebaseapp.com',
    messagingSenderId: '362585336247',
    measurementId: 'G-G78EBMGCBJ',
  },
};
