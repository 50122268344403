import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductEntity } from '@shared/domain/products/product.entity';
import { RetailProductsService } from 'retail/app/common/providers/retail-products.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UrlLinkPipe } from 'shared-ui/pipes/url-link.pipe';

@Component({
  selector: 'app-header-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  private productsService = inject(RetailProductsService);
  private router = inject(Router);
  private location = inject(Location);

  items = signal<ProductEntity[]>([]);
  isLoading = signal(false);
  form = new FormGroup({
    search: new FormControl(''),
  });

  ngOnInit(): void {
    this.setSearchValueFromUrl();
    this.form.valueChanges
      .pipe(
        map(values => values.search ?? ''),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(search => this.searchProducts(search));
  }

  goToProduct(item: ProductEntity) {
    return this.router.navigate(['/p/product', UrlLinkPipe.getUrl(item), item._id]);
  }

  goToSearch() {
    return this.router.navigate(['/p/products/search', this.form.value.search]);
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    });
  }

  protected searchProducts(search: string) {
    this.isLoading.set(true);
    this.productsService.searchProducts(search, 5).subscribe(products => {
      this.items.set(products);
      this.isLoading.set(false);
    });
  }

  protected setSearchValueFromUrl() {
    const route = '/p/products/search/';
    const path = this.location.path();
    const activeRoute = path.substring(0, route.length);
    if (activeRoute === route) {
      const queryIndex = path.indexOf('?');
      const search = path.substring(route.length, queryIndex >= 0 ? queryIndex : undefined);
      this.form.patchValue({ search });
      this.searchProducts(search);
    }
  }
}
