import { Injectable } from '@angular/core';
import { ProductGridEntity } from '@shared/domain/products/product-grid.entity';
import { chain } from 'lodash';
import { CartItem } from 'retail/app/common/models/cart-item';
import { OrdersService } from 'retail/app/orders/providers/orders.service';
import { RetailAnalyticsService } from 'retail/app/retail-analytics.service';
import { VAT } from 'shared-ui/models/vat';
import { StorageService } from 'shared-ui/providers/storage-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CartService {
  static readonly STORAGE_KEY: string = 'CART';
  static readonly STORAGE_VERSION_KEY: string = 'CART_VERSION';
  static readonly STORAGE_VERSION: string = 'v2';

  private cart: CartItem[] = [];

  private cartUpdate$ = new BehaviorSubject<CartItem[]>([]);

  constructor(
    private storageService: StorageService,
    private ordersService: OrdersService,
    private analyticsService: RetailAnalyticsService
  ) {
    this.checkVersion();
    this.loadCart();
    this.cartUpdate$.next(this.cart);
  }

  static getItemsPrice(items: CartItem[]) {
    return items.reduce((total: number, item: CartItem) => {
      return total + item.price * item.count * VAT;
    }, 0);
  }

  static getItemsRoundedPrice(items: CartItem[]) {
    return items.reduce((total: number, item: CartItem) => {
      return total + Math.round(item.price * item.count * VAT);
    }, 0);
  }

  static getItemsWeight(items: CartItem[]) {
    return items.reduce((total: number, item: CartItem) => {
      return total + item.weight * item.count;
    }, 0);
  }

  static getMaxItemWeight(items: CartItem[]): number {
    return chain(items)
      .map(item => item.weight)
      .max()
      .value();
  }

  get cartItems() {
    return this.cart;
  }

  get cartUpdate() {
    return this.cartUpdate$;
  }

  addToCart(product: ProductGridEntity) {
    if (this.productIsInCart(product)) {
      const item = this.cart.find(value => value.id === product._id)!;
      item.count++;
      this.analyticsService.logAddToCart(product, item.count);
    } else {
      this.cart.push({
        id: product._id,
        name: product.name,
        price: product.price,
        count: 1,
        image: product.defaultImage,
        weight: product.weight,
      });
      this.analyticsService.logAddToCart(product, 1);
    }
    this.persistCart();
  }

  updateCountForItem(item: CartItem, count: number) {
    if (count >= 1) {
      item.count = count;
    }
    this.persistCart();
  }

  removeFromCart(item: CartItem) {
    this.cart = this.cart.filter(value => value !== item);
    this.analyticsService.logRemoveFromCart(item);
    this.persistCart();
  }

  clearCart() {
    this.cart = [];
    this.persistCart();
  }

  getItemsPrice() {
    return CartService.getItemsPrice(this.cartItems);
  }

  private checkVersion() {
    if ((this.storageService.getItem(CartService.STORAGE_VERSION_KEY) ?? '') !== CartService.STORAGE_VERSION) {
      this.storageService.removeItem(CartService.STORAGE_KEY);
      this.storageService.setItem(CartService.STORAGE_VERSION_KEY, CartService.STORAGE_VERSION);
    }
  }

  private loadCart() {
    try {
      this.cart = JSON.parse(this.storageService.getItem(CartService.STORAGE_KEY) || '[]');
    } catch (error) {
      this.cart = [];
    }
  }

  private productIsInCart(product: ProductGridEntity) {
    return this.cart.some(value => value.id === product._id);
  }

  private persistCart() {
    this.ordersService.clearOrder();
    this.ordersService.patchOrder('cart', this.cart);
    this.storageService.setItem(CartService.STORAGE_KEY, JSON.stringify(this.cart));
    this.cartUpdate$.next(this.cart.slice());
  }
}
