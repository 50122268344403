<div #searchContainer>
  <div class="search-form">
    <label>{{ 'Search' | t }}</label>
    <input
      [value]="form.value.search"
      (focus)="dropdown.open(); focusInput(searchInput)"
      class="search-input"
      placeholder="{{ 'Search' | t }}..."
      autocomplete="off" />
    <button (click)="dropdown.close(); goToSearch()" mat-raised-button color="primary">
      <icon icon="search" />
    </button>
  </div>
</div>

<shared-dropdown #dropdown [width]="searchContainer.offsetWidth">
  <div style="position: relative; top: -50px; width: 100%">
    <div class="search-form">
      <label>{{ 'Search' | t }}</label>
      <input
        #searchInput
        [formControl]="form.controls.search"
        class="search-input"
        placeholder="{{ 'Search' | t }}..."
        autocomplete="off" />
      <button (click)="dropdown.close(); goToSearch()" mat-raised-button color="primary">
        <icon icon="search" />
      </button>
    </div>
    <mat-card class="mt-2">
      <mat-nav-list class="p-0">
        @for (item of items(); track item._id) {
          <button mat-list-item class="text-left" style="border: 0" (click)="dropdown.close(); goToProduct(item)">
            <span matListItemTitle>{{ item.name }}</span>
            <span matLine>{{ item.price | customerPrice | localeCurrency }}</span>
          </button>
        }
      </mat-nav-list>
      @if (items().length > 0) {
        <div class="text-center p-2">
          <button (click)="dropdown.close(); goToSearch()" mat-raised-button color="primary" class="w-100">
            <icon icon="search" />
            {{ 'Show all results' | t }}
          </button>
        </div>
      }
    </mat-card>
  </div>
</shared-dropdown>
