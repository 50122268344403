<div class="row">
  <div class="col-md-6">
    <a routerLink="/i/obsah/vse-o-nakupu/doprava-a-platba" class="mat-card" style="height: 330px">
      <div class="img-fill">
        <img class="mat-card-img-full" ngSrc="/assets/homepage/zasilkovna-banner.webp" alt="Zásilkovna" fill priority />
      </div>
    </a>
  </div>
  <div class="col-md-6">
    <a routerLink="/i/kotlikove-dotace" class="mat-card" style="height: 330px">
      <div class="img-fill">
        <img
          class="mat-card-img-full"
          ngSrc="/assets/homepage/dotace-na-kotle.webp"
          alt="Kotlíkové dotace"
          fill
          priority />
      </div>
    </a>
  </div>
</div>

<mat-card class="mb-4" style="min-height: 580px">
  <mat-card-header>
    <mat-card-title>{{ 'Recommended' | t }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @defer (on viewport) {
      <div class="row row-products">
        @for (product of recProducts(); track product._id) {
          <div class="col-md-6 col-lg-4 col-xl-3">
            <div class="product-card">
              <a [routerLink]="['/p/product', product | urlLink, product._id]">
                <div class="img-fill" style="height: 200px">
                  <img [alt]="product.name" [ngSrc]="product | productImageUrl" fill />
                </div>
                <h3>{{ product.name }}</h3>
              </a>
              <div class="price">
                {{ product.price | customerPrice | localeCurrency }}
              </div>
            </div>
          </div>
        }
      </div>
    } @placeholder {
      <div></div>
    }
  </mat-card-content>
</mat-card>

<mat-card class="mb-4" style="min-height: 580px">
  <mat-card-header>
    <mat-card-title>{{ 'News' | t }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @defer (on viewport) {
      <div class="row row-products">
        @for (product of lastProducts(); track product._id) {
          <div class="col-md-6 col-lg-4 col-xl-3">
            <div class="product-card">
              <a [routerLink]="['/p/product', product | urlLink, product._id]">
                <div class="img-fill" style="height: 200px">
                  <img [alt]="product.name" [ngSrc]="product | productImageUrl" fill />
                </div>
                <h3>{{ product.name }}</h3>
              </a>
              <div class="price">
                {{ product.price | customerPrice | localeCurrency }}
              </div>
            </div>
          </div>
        }
      </div>
    } @placeholder {
      <div></div>
    }
  </mat-card-content>
</mat-card>

<mat-card class="brands" style="min-height: 95px">
  @defer (on viewport) {
    <div class="row">
      @for (item of showedBrands(); track item) {
        <div class="col-20">
          <div class="img-fill"><img [alt]="item.label" [ngSrc]="item.imageUrl" fill /></div>
        </div>
      }
    </div>
  } @placeholder {
    <div></div>
  }
</mat-card>
